@import '../../../../scss/theme-bootstrap';

.content-block-large-formatter {
  .content-block-large {
    @include breakpoint($medium-up) {
      display: inline-block;
      width: 50%;
      float: left;
    }
  }
}
